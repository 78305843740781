import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { useGoogleMaps } from './MapsContext';

const MapContainer = styled.div`
  min-height: 360px;
  height: 100%;
`;
interface MapContext {
  map: google.maps.Map;
}
interface MapContextProps {
  children?: ReactNode;
}

const Context = createContext<MapContext | null>(null);

export function MapRenderer({ children }: MapContextProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const { maps } = useGoogleMaps();

  useEffect(() => {
    if (!maps || !ref.current) {
      return undefined;
    }
    const nextMap = new maps.Map(ref.current, {
      center: { lat: 39.833333, lng: -98.583333 },
      zoom: 4,
      disableDefaultUI: true,
    });
    setMap(nextMap);

    return () => {
      maps.event.clearInstanceListeners(nextMap);
    };
  }, [maps, setMap]);

  const ctx = useMemo(() => (map ? { map } : null), [map]);

  return (
    <Context.Provider value={ctx}>
      {map && children}
      <MapContainer ref={ref} />
    </Context.Provider>
  );
}

export function useMap(): MapContext {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Used outside of the MapContext');
  }

  return context;
}
