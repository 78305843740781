import React from 'react';

import { Marker } from '../google-maps/Marker';
import { DriverPoint } from './data/MatchesAPI';

interface DriverPointMarkerProps {
  position: { lat: number; lng: number };
  options?: google.maps.ReadonlyMarkerOptions;
  driverName: string;
  point: DriverPoint;
  isWaypoint: boolean;
}
export function DriverPointMarker({
  position,
  options,
  driverName,
  point,
  isWaypoint,
}: DriverPointMarkerProps) {
  return (
    <Marker
      position={position}
      options={
        isWaypoint
          ? {
              icon: {
                url:
                  point.type === 'pickup'
                    ? 'http://maps.google.com/mapfiles/ms/icons/green.png'
                    : 'http://maps.google.com/mapfiles/ms/icons/orange.png',
              },
              ...options,
            }
          : {
              label: {
                text: point.type === 'pickup' ? 'P' : 'D',
                color: 'white',
                fontWeight: 'bold',
              },
              ...options,
            }
      }
      infoWindowContent={`Driver: <b>${driverName}</b><br>Type: <b>${
        point.type
      }</b><br>${
        point.type === 'pickup' ? 'Pickup date' : 'Delivery date'
      }: <b>${new Date(
        point.date,
      ).toLocaleDateString()}</b><br>Dispatcher: <b>${
        point.created_by.name
      }</b><br>Phone: <b>${point.created_by.phone ?? ''}</b>`}
    />
  );
}
