import { useDeepEqualMemo } from '@superdispatch/hooks';
import { useEffect } from 'react';

import { useMap } from './MapRenderer';
import { useGoogleMaps } from './MapsContext';

interface MarkerProps {
  position: { lat: number; lng: number };
  options?: google.maps.ReadonlyMarkerOptions;
  infoWindowContent: string;
}
export function Marker({ position, options, infoWindowContent }: MarkerProps) {
  const { map } = useMap();
  const { maps } = useGoogleMaps();

  const memoizedOptions = useDeepEqualMemo(() => options, [options]);
  const memoizedPosition = useDeepEqualMemo(() => position, [position]);

  useEffect(() => {
    if (!maps) {
      return;
    }
    const marker = new maps.Marker({
      map,
      position: new maps.LatLng({
        lat: memoizedPosition.lat,
        lng: memoizedPosition.lng,
      }),

      ...memoizedOptions,
    });

    const infoWindow = new maps.InfoWindow({
      content: infoWindowContent,
    });
    marker.addListener('click', () => {
      infoWindow.open(map, marker);
    });
    return () => {
      marker.setMap(null);
      infoWindow.close();
    };
  }, [memoizedPosition, maps, map, memoizedOptions, infoWindowContent]);

  return null;
}
