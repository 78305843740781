import { createHTTP, HTTPError, HTTPRequestOptions } from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
import { noop } from 'lodash-es';
import { useMemo } from 'react';

import { redirectToAuth } from '../Authenticate';
import { API_BASE_URL } from '../data/APIConstants';
import { useMatchesContext } from '../MatchesContext';

export interface API {
  request: <TParams extends URITemplateParams = URITemplateParams>(
    endpoint: string,
    options?: TParams & HTTPRequestOptions,
  ) => Promise<Response>;
  requestJSON: <TData, TParams extends URITemplateParams = URITemplateParams>(
    endpoint: string,
    options?: TParams & HTTPRequestOptions,
  ) => Promise<TData>;
}

function handleError(error: unknown) {
  if (error instanceof HTTPError) {
    if (error.response.status === 401 || error.response.status === 403) {
      redirectToAuth();
      return new Promise<never>(noop);
    }
  }
  return Promise.reject(new Error('Failed to fetch data'));
}

export function useAPI(): API {
  const { token } = useMatchesContext();
  return useMemo((): API => {
    const { requestJSON, request } = createHTTP({
      baseURL: API_BASE_URL,
      headers: !token ? undefined : { Authorization: `Bearer ${token}` },
    });

    return {
      request: (endpoint, options) =>
        request(endpoint, options).catch(handleError),
      requestJSON: <
        TData,
        TParams extends URITemplateParams = URITemplateParams
      >(
        endpoint: string,
        options?: TParams & HTTPRequestOptions,
      ) => requestJSON<TData, TParams>(endpoint, options).catch(handleError),
    };
  }, [token]);
}
