import {
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { useMatchingParams, usePricePredictions } from './data/MatchesAPI';
import { currencyFormatter } from './FormatUtils';

const vehicleDisplayTypeNames: { [key: string]: string } = {
  sedan: 'Sedan',
  suv: 'SUV',
  van: 'VAN',
  pickup_2_doors: 'Pickup 2 doors',
  pickup_4_doors: 'Pickup 4 doors',
};

export const CenterContent = styled.div`
  height: 103px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function EstimatedPricesTable() {
  const params = useMatchingParams();
  const {
    data: pricePredictions,
    error: pricePredictionsError,
  } = usePricePredictions();

  if (!params) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        title="Estimated Prices"
        titleTypographyProps={{ variant: 'h4' }}
      />
      {!pricePredictions ? (
        <CenterContent>
          <CircularProgress />
        </CenterContent>
      ) : pricePredictionsError ? (
        <CenterContent>
          <Typography color="error">{pricePredictionsError.message}</Typography>
        </CenterContent>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Inoperable</TableCell>
              {pricePredictions.map(
                (data) =>
                  data.is_inoperable && (
                    <TableCell key={data.vehicle_type_name} align="center">
                      {vehicleDisplayTypeNames[data.vehicle_type_name]}
                    </TableCell>
                  ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover={true}>
              <TableCell>No</TableCell>
              {pricePredictions.map(
                (data) =>
                  !data.is_inoperable && (
                    <TableCell key={data.vehicle_type_name} align="center">
                      {currencyFormatter.format(data.estimated_price)}
                    </TableCell>
                  ),
              )}
            </TableRow>
            <TableRow hover={true}>
              <TableCell>Yes</TableCell>
              {pricePredictions.map(
                (data) =>
                  data.is_inoperable && (
                    <TableCell key={data.vehicle_type_name} align="center">
                      {currencyFormatter.format(data.estimated_price)}
                    </TableCell>
                  ),
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Card>
  );
}
