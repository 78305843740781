import { useMemo } from 'react';

import { useAPI } from '../api/API';

export function useCarrierAPI() {
  const { requestJSON } = useAPI();

  return useMemo(
    () => ({
      updateCarrierNote: (guid: string, note: string) =>
        requestJSON(`POST /internal/api/carrier/${guid}/add_note`, {
          json: { note },
        }),
    }),
    [requestJSON],
  );
}
