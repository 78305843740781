import { toFinite, trim } from 'lodash-es';
import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Authenticate } from './Authenticate';
import { convertMilesToKm } from './FormatUtils';
import { GeoPoint } from './GeoField';

export type RankingFunctionName = 'product' | 'harmonic_mean';
export interface Shipper {
  guid: string;
  name: string;
}

export interface MatchesParams {
  pickupPoint?: GeoPoint;
  deliveryPoint?: GeoPoint;

  daysHalfLife: number;
  distanceHalfLife: number;
  searchRadius: number;
  searchHistory: number;
  rankingFunction?: RankingFunctionName;
  shipper?: Shipper;
  ordersMinCount?: string;
  ordersMaxCount?: string;
}

export interface MatchesContext {
  token?: string;
  params: MatchesParams;
  setParams: (action: SetStateAction<MatchesParams>) => void;
}

const defaultParams: MatchesParams = {
  daysHalfLife: 45,
  distanceHalfLife: 50,
  searchRadius: convertMilesToKm(95),
  searchHistory: 90,
  rankingFunction: 'harmonic_mean',
};

const Context = createContext<MatchesContext | null>(null);

export function useMatchesContext(): MatchesContext {
  const result = useContext(Context);
  if (!result) {
    throw new Error('Outside of Matches context');
  } else {
    return result;
  }
}

function parseParams(search: string): MatchesParams {
  const params = new URLSearchParams(search);

  function parsePoint(name: string): undefined | GeoPoint {
    const lat = toFinite(params.get(`${name}_latitude`));
    const lng = toFinite(params.get(`${name}_longitude`));
    const address = trim(params.get(`${name}_address`) || '');

    return !lat || !lng || !address ? undefined : { lat, lng, address };
  }

  function parseNumber(name: string, defaultValue: number): number {
    return toFinite(params.get(name)) || defaultValue;
  }

  function parseShipperInfo() {
    const guid = params.get('shipper_guid');
    const name = params.get('shipper_name');

    return !guid || !name ? undefined : { guid, name };
  }

  return {
    pickupPoint: parsePoint('pickup'),
    deliveryPoint: parsePoint('delivery'),

    daysHalfLife: parseNumber('days_half_life', defaultParams.daysHalfLife),
    distanceHalfLife: parseNumber(
      'dist_half_life',
      defaultParams.distanceHalfLife,
    ),
    searchRadius: parseNumber('search_radius', defaultParams.searchRadius),
    searchHistory: parseNumber('search_history', defaultParams.searchHistory),

    shipper: parseShipperInfo(),

    rankingFunction: (params.get('ranking_function') ||
      defaultParams.rankingFunction) as RankingFunctionName,

    ordersMinCount: params.get('orders_min_count') || undefined,
    ordersMaxCount: params.get('orders_max_count') || undefined,
  };
}

export function stringifyParams(
  params: MatchesParams,
  locationSearch: string,
): string {
  const searchParams = new URLSearchParams(locationSearch);

  function storePoint(name: string, point?: GeoPoint) {
    if (point?.lat && point.lng && point.address) {
      searchParams.set(`${name}_address`, point.address);
      searchParams.set(`${name}_latitude`, point.lat.toString());
      searchParams.set(`${name}_longitude`, point.lng.toString());
    } else {
      searchParams.delete(`${name}_address`);
      searchParams.delete(`${name}_latitude`);
      searchParams.delete(`${name}_longitude`);
    }
  }

  function storeNumber(
    name: string,
    value: number | undefined,
    defaultValue: number,
  ) {
    if (value == null || value === defaultValue) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value.toString());
    }
  }

  function storeShipperInfo(shipper?: Shipper) {
    if (shipper) {
      searchParams.set('shipper_guid', shipper.guid);
      searchParams.set('shipper_name', shipper.name);
    } else {
      searchParams.delete('shipper_guid');
      searchParams.delete('shipper_name');
    }
  }

  function storeOrdersCount(
    minCount: string | undefined,
    maxCount: string | undefined,
  ) {
    if (minCount) {
      searchParams.set('orders_min_count', minCount.toString());
    } else {
      searchParams.delete('orders_min_count');
    }
    if (maxCount) {
      searchParams.set('orders_max_count', maxCount.toString());
    } else {
      searchParams.delete('orders_max_count');
    }
  }

  storePoint('pickup', params.pickupPoint);
  storePoint('delivery', params.deliveryPoint);
  storeNumber(
    'days_half_life',
    params.daysHalfLife,
    defaultParams.daysHalfLife,
  );
  storeNumber(
    'dist_half_life',
    params.distanceHalfLife,
    defaultParams.distanceHalfLife,
  );
  storeNumber('search_radius', params.searchRadius, defaultParams.searchRadius);
  storeNumber(
    'search_history',
    params.searchHistory,
    defaultParams.searchHistory,
  );
  storeShipperInfo(params.shipper);
  // searchParams.set('ranking_function', params.rankingFunction || defaultParams.rankingFunction);
  storeOrdersCount(params.ordersMinCount, params.ordersMaxCount);

  return searchParams.toString();
}

function useToken(locationSearch: string) {
  const token = useMemo(
    () => localStorage.getItem('lm_token') || undefined,
    [],
  );

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    const tokenParam = params.get('token');

    if (tokenParam) {
      localStorage.setItem('lm_token', tokenParam);
      window.location.replace('/');
    }
  }, [locationSearch]);

  return token;
}

export function MatchesContextProvider({ children }: { children: ReactNode }) {
  const history = useHistory();
  const { search } = useLocation();
  const token = useToken(search);

  const params = useMemo(() => parseParams(search), [search]);
  const setParams = useCallback(
    (action: SetStateAction<MatchesParams>) => {
      const nextParams =
        typeof action !== 'function'
          ? action
          : action(parseParams(history.location.search));

      history.push({
        search: stringifyParams(nextParams, history.location.search),
      });
    },
    [history],
  );

  const ctx = useMemo((): MatchesContext => ({ token, params, setParams }), [
    token,
    params,
    setParams,
  ]);

  if (!token) {
    return <Authenticate />;
  }

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
