import { useDeepEqualMemo } from '@superdispatch/hooks';
import {
  HTTPResource,
  HTTPResourceInput,
  HTTPResourceOptions,
  useHTTPResource,
} from '@superdispatch/http-resource';
import { URITemplateParams } from '@superdispatch/uri';
import { useMemo } from 'react';

import { useAPI } from './API';

export type APIPageBaseOptions<TData> = Omit<
  HTTPResourceOptions<TData[]>,
  'compare'
>;

export function useAPIList<
  TData,
  TParams extends URITemplateParams = URITemplateParams
>(
  input: null | HTTPResourceInput<TParams>,
  {
    focusThrottleInterval = 30 * 1000,
    revalidateOnFocus = false,
    errorRetryCount = 1,
    ...resourceOptions
  }: APIPageBaseOptions<TData> = {},
): HTTPResource<TData[]> {
  const resourceInput = useDeepEqualMemo<null | HTTPResourceInput<TParams>>(() => {
    if (!Array.isArray(input)) {
      return input;
    }
    let [template, options] = input;
    if (typeof options == 'object' && typeof options.json == 'object') {
      options = {
        ...options,
        body: JSON.stringify(options.json),
        json: undefined,
        headers: { ...options.headers, 'content-type': 'application/json' },
      };
    }
    return [template, options];
  }, [input]);
  const { requestJSON } = useAPI();

  const { data, error, mutate, revalidate, isValidating } = useHTTPResource<
    TData[]
  >(resourceInput, requestJSON, {
    errorRetryCount,
    revalidateOnFocus,
    focusThrottleInterval,
    ...resourceOptions,
  });

  return useMemo(() => ({ data, error, mutate, revalidate, isValidating }), [
    data,
    error,
    isValidating,
    mutate,
    revalidate,
  ]);
}
