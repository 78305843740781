import 'styled-components/macro';

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import {
  Block,
  CloseOutlined,
  FlagOutlined,
  InfoOutlined,
  PhoneDisabled,
  RemoveCircleOutline,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Button, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CarrierCapacityTable } from './CarrierCapacityTable';
import { useCarrierAPI } from './data/CarrierAPI';
import {
  LoadMatchesResponse,
  useBigTrailerCarriers,
  useNearbyCarriers,
  useNewCarriers,
} from './data/MatchesAPI';
import { digitFormatter } from './FormatUtils';
import { LastPricesList } from './LastPricesList';
import { NewCarriersTable } from './NewCarriersTable';

interface LoadMatchesResult {
  loadMatches: LoadMatchesResponse | undefined;
  refetchLoadMatches: () => void;
}
function useRankedCarriers({
  loadMatches,
  refetchLoadMatches,
}: LoadMatchesResult) {
  const carrierIds = useMemo(
    () => loadMatches?.carriers.map((x) => x.carrier_id) || [],
    [loadMatches],
  );

  const {
    data: nearbyCarriers,
    error: nearbyCarriersError,
    isValidating: isNearbyCarriersValidating,
    revalidate: refetchNearbyCarriers,
  } = useNearbyCarriers(carrierIds);

  const mappedNearbyCarriersData = useMemo(
    () => ({
      loadMatchesRankIndexMap: new Map(
        loadMatches?.carriers.map((carrier, i) => [carrier.carrier_id, i + 1]),
      ),
      routeFrequencyMap: new Map(
        nearbyCarriers?.route_frequency.map((x) => [x.carrier_id, x]),
      ),
      enclosedInoperableMap: new Map(
        nearbyCarriers?.enclosed_and_inoperable.map((x) => [x.carrier_id, x]),
      ),
    }),
    [loadMatches, nearbyCarriers],
  );

  const {
    data: bigTrailerCarriers,
    error: bigTrailerCarriersError,
    isValidating: isBigTrailerCarriersValidating,
    revalidate: refetchBigTrailerCarriers,
  } = useBigTrailerCarriers(carrierIds);

  return {
    loadMatches,
    nearbyCarriers,
    isNearbyCarriersValidating,
    nearbyCarriersError,
    refetchNearbyCarriers,
    lastPrices: nearbyCarriers?.last_prices,
    bigTrailerCarriers,
    isBigTrailerCarriersValidating,
    bigTrailerCarriersError,
    refetchBigTrailerCarriers,
    refetchLoadMatches,
    ...mappedNearbyCarriersData,
  };
}

const CellSkeleton = styled(Skeleton)`
  margin: 0 auto;
`;
const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
`;
interface MatchesResultProps {
  selectCarrier: (carrierId: number) => void;
  loadMatchesResult: LoadMatchesResult;
}
export function MatchesResult({
  selectCarrier,
  loadMatchesResult,
}: MatchesResultProps) {
  const {
    loadMatches,
    loadMatchesRankIndexMap,
    routeFrequencyMap,
    enclosedInoperableMap,
    lastPrices,
    isBigTrailerCarriersValidating,
    bigTrailerCarriers,
    nearbyCarriers,
    isNearbyCarriersValidating,
    nearbyCarriersError,
    bigTrailerCarriersError,
    refetchBigTrailerCarriers,
    refetchNearbyCarriers,
    refetchLoadMatches,
  } = useRankedCarriers(loadMatchesResult);

  const {
    data: newCarriers,
    error: newCarriersError,
    isValidating: isNewCarriersValidating,
    revalidate: refetchNewCarriers,
  } = useNewCarriers();

  const [isSortedByRouteFrequency, setIsSortedByRouteFrequency] = useState(
    false,
  );
  const [focusedTab, setFocusedTab] = useState(1);

  const loadMatchesSortedByRouteFrequency = useMemo(() => {
    if (!loadMatches) {
      return [];
    }

    return Array.from(loadMatches.carriers).sort((current, next) => {
      const currentCarrier = routeFrequencyMap.get(current.carrier_id);
      const nextCarrier = routeFrequencyMap.get(next.carrier_id);

      if (currentCarrier && nextCarrier) {
        return nextCarrier.route_frequency - currentCarrier.route_frequency;
      }

      return 0;
    });
  }, [loadMatches, routeFrequencyMap]);

  const sortedLoadMatches = useMemo(() => {
    if (loadMatches) {
      return isSortedByRouteFrequency
        ? loadMatchesSortedByRouteFrequency
        : loadMatches.carriers;
    }
    return undefined;
  }, [
    loadMatches,
    loadMatchesSortedByRouteFrequency,
    isSortedByRouteFrequency,
  ]);

  const { addSnackbar } = useSnackbarStack();
  const { updateCarrierNote } = useCarrierAPI();

  const [activeCarrierGuid, setActiveCarrierGuid] = useState<
    string | undefined
  >(undefined);
  const formik = useFormikEnhanced({
    initialValues: {
      carrier_note: loadMatches?.carriers
        .find((carrier) => carrier.guid === activeCarrierGuid)
        ?.note.trim(),
    },
    onSubmit: (value) => {
      if (activeCarrierGuid) {
        return updateCarrierNote(activeCarrierGuid, value.carrier_note ?? '');
      }
      return Promise.resolve();
    },
    onSubmitSuccess: () => {
      setActiveCarrierGuid(undefined);
      refetchLoadMatches();
      addSnackbar('Carrier note updated', {
        variant: 'success',
      });
    },
    onSubmitFailure: (error) => {
      setActiveCarrierGuid(undefined);
      addSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  useEffect(() => {
    if (nearbyCarriersError) {
      addSnackbar(
        'An error occured while loading data. Try refreshing the page.',
        {
          variant: 'error',
        },
      );
    }
  }, [addSnackbar, nearbyCarriersError]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} md={8} xs={12}>
        <Card
          css={`
            display: flex;
            flex-direction: column;
            max-height: 80vh;
          `}
        >
          <CardHeader
            title={
              <Box display="flex" justifyContent="space-between">
                <div>
                  <Typography variant="h4" gutterBottom={true}>
                    Carrier ranks
                  </Typography>
                  <Grid container={true} spacing={2}>
                    <Grid item={true}>
                      <Block fontSize="small" css="vertical-align: middle" />
                      <small> - Blacklisted</small>
                    </Grid>
                    <Grid item={true}>
                      <PhoneDisabled
                        fontSize="small"
                        color="action"
                        css="vertical-align: middle"
                      />
                      <small> - Do not call</small>
                    </Grid>
                    <Grid item={true}>
                      <RemoveCircleOutline
                        fontSize="small"
                        color="error"
                        css="vertical-align: middle"
                      />
                      <small> - Excluded</small>
                    </Grid>
                  </Grid>
                </div>
                <FormControlLabel
                  disabled={routeFrequencyMap.size < 1}
                  checked={isSortedByRouteFrequency}
                  onChange={(_, checked) => {
                    setIsSortedByRouteFrequency(checked);
                  }}
                  control={<Switch color="primary" />}
                  label="Sort by route frequency"
                />
              </Box>
            }
          />
          <TableContainer css="overflow:auto;">
            <Table size="small" stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" align="center">
                    Rank
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <FlagOutlined fontSize="small" />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell padding="none" align="center" />
                  <TableCell align="left">Phone</TableCell>
                  <TableCell padding="checkbox">USDOT</TableCell>
                  <TableCell align="center" padding="checkbox">
                    Score
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    Route frequency
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    Enclosed
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    Inoperable
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedLoadMatches?.map((carrier) => {
                  const loadMatchesRankIndex = loadMatchesRankIndexMap.get(
                    carrier.carrier_id,
                  );
                  const carrierRouteFrequency = routeFrequencyMap.get(
                    carrier.carrier_id,
                  );
                  const enclosedInoperable = enclosedInoperableMap.get(
                    carrier.carrier_id,
                  );

                  return (
                    <TableRow key={carrier.carrier_id} hover={true}>
                      <TableCell padding="none" align="center">
                        {loadMatchesRankIndex}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        <Checkbox
                          size="small"
                          defaultChecked={false}
                          disabled={
                            !carrier.drivers?.some(
                              (driver) =>
                                driver.location != null ||
                                driver.points.length > 0,
                            )
                          }
                          onChange={() => {
                            selectCarrier(carrier.carrier_id);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://carrier.superdispatch.com/console/carriers/carrier/${carrier.carrier_id}`}
                          >
                            {carrier.name}&nbsp;&nbsp;
                          </Link>
                          {carrier.is_blacklisted && <Block fontSize="small" />}
                          {carrier.exclusion_reason === 'do_not_call' && (
                            <PhoneDisabled color="action" fontSize="small" />
                          )}
                          {carrier.exclusion_reason === 'remove_totally' && (
                            <RemoveCircleOutline
                              fontSize="small"
                              color="error"
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell padding="none" align="center">
                        <IconButton
                          onClick={() => {
                            setActiveCarrierGuid(carrier.guid);
                          }}
                        >
                          <InfoOutlined
                            fontSize="small"
                            color={carrier.note === '' ? 'action' : 'error'}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell align="left">
                        {carrier.phone_numbers}
                      </TableCell>
                      <TableCell padding="checkbox">{carrier.us_dot}</TableCell>
                      <TableCell align="center" padding="checkbox">
                        <strong>
                          {digitFormatter.format(carrier.total_rank)}
                        </strong>
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {!nearbyCarriers ? (
                          <CellSkeleton />
                        ) : (
                          carrierRouteFrequency?.route_frequency
                        )}
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {!nearbyCarriers ? (
                          <CellSkeleton />
                        ) : (
                          enclosedInoperable?.enclosed
                        )}
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {!nearbyCarriers ? (
                          <CellSkeleton />
                        ) : (
                          enclosedInoperable?.inoperable
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      <Grid item={true} md={4} xs={12}>
        <Card
          css={`
            display: flex;
            flex-direction: column;
            max-height: 80vh;
          `}
        >
          <Tabs
            value={focusedTab}
            onChange={(_, value) => setFocusedTab(value)}
          >
            <Tab value={1} label="Last prices" />
            <Tab value={2} label="Carriers with 4+ capacity" />
            <Tab value={3} label="New carriers" />
          </Tabs>

          <div css="overflow: auto;">
            {focusedTab === 1 && (
              <LastPricesList
                isLoading={
                  (!lastPrices && !nearbyCarriersError) ||
                  isNearbyCarriersValidating
                }
                error={nearbyCarriersError}
                data={lastPrices}
                refetch={refetchNearbyCarriers}
              />
            )}

            {focusedTab === 2 && (
              <CarrierCapacityTable
                data={bigTrailerCarriers}
                isLoading={
                  (!bigTrailerCarriers && !bigTrailerCarriersError) ||
                  isBigTrailerCarriersValidating
                }
                error={bigTrailerCarriersError}
                refetch={refetchBigTrailerCarriers}
              />
            )}

            {focusedTab === 3 && (
              <NewCarriersTable
                data={newCarriers}
                isLoading={
                  (!newCarriers && !newCarriersError) || isNewCarriersValidating
                }
                error={newCarriersError}
                refetch={refetchNewCarriers}
              />
            )}
          </div>
        </Card>
      </Grid>

      <Dialog
        onClose={() => {
          setActiveCarrierGuid(undefined);
        }}
        aria-labelledby="carrier-note-title"
        open={activeCarrierGuid !== undefined}
        maxWidth="xs"
        fullWidth={true}
      >
        <FormikProvider value={formik}>
          <Form>
            <StyledDialogTitle id="carrier-note-title">
              {
                loadMatches?.carriers.find(
                  (carrier) => carrier.guid === activeCarrierGuid,
                )?.name
              }
              <IconButton
                aria-label="close"
                onClick={() => {
                  setActiveCarrierGuid(undefined);
                }}
              >
                <CloseOutlined />
              </IconButton>
            </StyledDialogTitle>

            <DialogContent>
              <FormikTextField
                name="carrier_note"
                multiline={true}
                rowsMax={6}
                fullWidth={true}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Grid>
  );
}
