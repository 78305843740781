import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import { Button, Color } from '@superdispatch/ui';
import React from 'react';
import styled from 'styled-components';

import { LastPrice } from './data/MatchesAPI';
import { CenterContent } from './EstimatedPricesTable';
import { currencyFormatter } from './FormatUtils';

const Direction = styled.div`
  padding-left: 16px;
  position: relative;
`;

const Line = styled.div`
  position: absolute;
  border-left: 1px dashed grey;
  top: 4px;
  bottom: 4px;
  left: 4px;

  &:before,
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${Color.Yellow300};
    left: -6px;
  }

  &:after {
    background-color: ${Color.Green300};
    bottom: 0;
  }
`;

interface LastPricesListProps {
  isLoading?: boolean;
  error?: Error;
  data?: LastPrice[];
  refetch?: () => void;
}

export function LastPricesList({
  isLoading,
  error,
  data,
  refetch,
}: LastPricesListProps) {
  if (isLoading) {
    return (
      <CenterContent>
        <CircularProgress />
      </CenterContent>
    );
  }

  if (error) {
    return (
      <CenterContent>
        <Typography color="error" gutterBottom={true}>
          {error.message}
        </Typography>
        <Button variant="outlined" color="primary" onClick={refetch}>
          Reload
        </Button>
      </CenterContent>
    );
  }

  return (
    <List disablePadding={true} dense={true}>
      {data?.map(
        ({
          id,
          name: carrierName,
          phone_numbers,
          pickup_city,
          pickup_state,
          delivery_city,
          delivery_state,
          price,
          load_count,
          vehicle_types,
        }) => (
          <ListItem key={id} divider={true}>
            <Grid container={true}>
              <Grid item={true} xs={12} sm={8}>
                <Typography variant="subtitle2" gutterBottom={true}>
                  {carrierName}
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Phone fontSize="small" color="action" />
                  <Typography variant="h5" align="right">
                    &nbsp;{phone_numbers}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <Direction>
                  <Line />
                  <Typography>{`${pickup_city}, ${pickup_state}`}</Typography>
                  <Typography>{`${delivery_city}, ${delivery_state}`}</Typography>
                </Direction>
              </Grid>
              <Grid item={true} xs={6} sm={3}>
                <Typography>
                  Price: <strong>{currencyFormatter.format(price)}</strong>
                </Typography>
                <Typography>
                  Loads: <strong>{load_count}</strong>
                </Typography>
              </Grid>
              <Grid item={true} xs={6} sm={3}>
                <Typography>Vehicle types:</Typography>
                <Typography>
                  <strong>{vehicle_types}</strong>
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ),
      )}
    </List>
  );
}
