import 'styled-components/macro';

import { Card, CardHeader } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

import {
  DirectionsRenderer,
  useDirectionsService,
} from '../google-maps/Directions';
import { MapRenderer } from '../google-maps/MapRenderer';
import { useGoogleMaps } from '../google-maps/MapsContext';
import { useGoogleMapsAPI } from '../google-maps/MapUtils';
import { LoadMatchesResponse, MatchedCarrier } from './data/MatchesAPI';
import { DriverMarker } from './DriverMarker';
import { DriverPointMarker } from './DriverPointMarker';
import { useMatchesContext } from './MatchesContext';
import { RouteInfo } from './RouteInfo';

const routeColors: string[] = [
  'maroon',
  'red',
  'yellow',
  'olive',
  'green',
  'pink',
  'purple',
  'fuchsia',
  'teal',
  'navy',
  'aqua',
];

interface MapComponentProps {
  checkedCarriers: number[];
  loadMatches: LoadMatchesResponse | undefined;
}
export function MapComponent({
  checkedCarriers,
  loadMatches,
}: MapComponentProps) {
  const [driverPointDirections, setdriverPointDirections] = useState<
    google.maps.DirectionsResult[]
  >([]);

  const { maps } = useGoogleMaps();
  const { fetchRoute } = useGoogleMapsAPI();
  const {
    params: { pickupPoint, deliveryPoint },
  } = useMatchesContext();

  const { data: loadDirection } = useDirectionsService(
    pickupPoint && deliveryPoint
      ? {
          origin: pickupPoint,
          destination: deliveryPoint,
          travelMode: maps?.TravelMode.DRIVING,
        }
      : undefined,
  );

  const selectedCarriers = useMemo(() => {
    if (!maps || !loadMatches?.carriers.length || !checkedCarriers.length) {
      return [];
    }
    const carriers: MatchedCarrier[] = [];

    for (const carrier of loadMatches.carriers) {
      if (
        carrier.drivers?.length &&
        checkedCarriers.includes(carrier.carrier_id)
      ) {
        carriers.push(carrier);
      }
    }
    return carriers;
  }, [checkedCarriers, loadMatches, maps]);

  useEffect(() => {
    const directionResults: Array<Promise<google.maps.DirectionsResult>> = [];

    let current = true;

    selectedCarriers.forEach((carrier) => {
      carrier.drivers?.forEach((driver) => {
        const waypoints: google.maps.DirectionsWaypoint[] = driver.points.map(
          (point) => ({
            location: new google.maps.LatLng({
              lat: point.latitude,
              lng: point.longitude,
            }),
            stopover: true,
          }),
        );
        if (waypoints.length >= 2) {
          const origin = waypoints.shift()?.location;
          const destination = waypoints.pop()?.location;
          const requestPoint: google.maps.DirectionsRequest = {
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
            waypoints,
          };
          directionResults.push(fetchRoute(requestPoint));
        }
      });
    });
    void Promise.all(directionResults).then(
      (values) => current && setdriverPointDirections(values),
    );

    return () => {
      current = false;
    };
  }, [loadMatches, checkedCarriers, selectedCarriers, fetchRoute]);

  return (
    <Card css="height: 100%;">
      <CardHeader title={<RouteInfo direction={loadDirection} />} />
      <MapRenderer>
        <DirectionsRenderer directions={loadDirection} />
        {driverPointDirections.map((direction, index) => (
          <DirectionsRenderer
            key={index}
            directions={direction}
            options={{
              polylineOptions: {
                strokeColor: routeColors[index % 8],
              },
              suppressMarkers: true,
            }}
          />
        ))}
        {selectedCarriers.map((carrier) => (
          <React.Fragment key={carrier.guid}>
            {carrier.drivers?.map((driver) => (
              <React.Fragment key={driver.guid}>
                {driver.location && (
                  <DriverMarker
                    position={{
                      lat: driver.location.latitude,
                      lng: driver.location.longitude,
                    }}
                    options={{
                      icon: {
                        url:
                          'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                      },
                    }}
                    carrierName={carrier.name}
                    driver={driver}
                  />
                )}
                {driver.points.map((point, index) => (
                  <DriverPointMarker
                    key={`${point.latitude}@${point.longitude}@${driver.guid}`}
                    position={{ lat: point.latitude, lng: point.longitude }}
                    driverName={driver.name}
                    point={point}
                    isWaypoint={
                      index !== 0 && index !== driver.points.length - 1
                    }
                  />
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </MapRenderer>
    </Card>
  );
}
