import { get, set } from 'lodash-es';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const KEY = '__google_maps_loader__';
const CALLBACK_KEY = '__google_maps_loader_callback__';
const MAPS_URL = 'https://maps.googleapis.com/maps/api/js';

export interface MapsContext {
  maps?: typeof google.maps;
}

const Context = createContext<MapsContext>({});

export function useGoogleMaps(): MapsContext {
  return useContext(Context);
}

function getGoogleMaps() {
  return get(window, 'google.maps') as undefined | typeof google.maps;
}

function loadAPI(key: string, onSuccess: () => void): void {
  const maps = getGoogleMaps();

  if (maps) {
    return onSuccess();
  }

  const existScript = document.getElementById(KEY);

  if (existScript) {
    set(window, CALLBACK_KEY, onSuccess);

    return;
  }

  set(window, CALLBACK_KEY, onSuccess);

  const script = document.createElement('script');

  script.id = KEY;
  script.async = true;
  script.defer = true;
  script.src = `${MAPS_URL}?libraries=places&key=${key}&callback=${CALLBACK_KEY}`;

  document.body.appendChild(script);
}

export function GoogleMapsProvider({ children }: { children: ReactNode }) {
  const [maps, setMaps] = useState<undefined | typeof google.maps>(
    getGoogleMaps,
  );
  const ctx = useMemo(() => ({ maps }), [maps]);

  useEffect(() => {
    if (maps) {
      return;
    }

    return loadAPI('AIzaSyCIcGxVSXhA8PuNNm7oJziUACtGWoOWZEY', () =>
      setMaps(getGoogleMaps),
    );
  }, [maps]);

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
