export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const digitFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const convertKmToMiles = (kilometers: number) => kilometers / 1.60934;
export const convertMilesToKm = (miles: number) => miles * 1.60934;
