import { useEffect } from 'react';

import { API_BASE_URL } from './data/APIConstants';

export function Authenticate() {
  useEffect(() => {
    redirectToAuth();
  }, []);

  return null;
}

export function redirectToAuth() {
  localStorage.removeItem('lm_token');
  window.location.replace(
    `${API_BASE_URL}/authorize?backURL=${window.location.href}`,
  );
}
