import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import React, { useEffect, useState } from 'react';

import { GoogleMapsProvider } from '../google-maps/MapsContext';
import {
  useLoadMatches,
  useMatchingParams,
  useNewLoadMatches,
} from './data/MatchesAPI';
import { CenterContent, EstimatedPricesTable } from './EstimatedPricesTable';
import { MapComponent } from './MapComponent';
import { MatchesResult } from './MatchesResult';
import { MatchesSearchParams } from './MatchesSearchParams';

export function MatchesPage() {
  const [selectedCarriers, setSelectedCarrier] = useState<number[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { addSnackbar } = useSnackbarStack();
  const oldLoadMatches = useLoadMatches();
  const newLoadMatches = useNewLoadMatches();
  const params = useMatchingParams();
  const tabs = [oldLoadMatches, newLoadMatches];
  const currentTabData = tabs[tabIndex];
  const {
    data: loadMatches,
    error: loadMatchesError,
    isValidating: isLoadMatchesValidating,
    revalidate: refetchLoadMatches,
  } = currentTabData;

  useEffect(() => {
    if (loadMatchesError) {
      addSnackbar(
        'An error occured while loading data. Try refreshing the page.',
        {
          variant: 'error',
        },
      );
    }
  }, [addSnackbar, loadMatchesError]);

  const selectCarrier = (carrierId: number) => {
    if (selectedCarriers.length < 1) {
      setSelectedCarrier([carrierId]);
    } else if (selectedCarriers.includes(carrierId)) {
      setSelectedCarrier(selectedCarriers.filter((x) => x !== carrierId));
    } else {
      setSelectedCarrier(selectedCarriers.concat(carrierId));
    }
  };
  return (
    <GoogleMapsProvider>
      <Box padding={1}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item={true} md={8} xs={12}>
                <MatchesSearchParams
                  clearCheckedCarriers={() => setSelectedCarrier([])}
                />
                <Box paddingBottom={2} />
                <EstimatedPricesTable />
              </Grid>

              <Grid item={true} md={4} xs={12}>
                <MapComponent
                  checkedCarriers={selectedCarriers}
                  loadMatches={loadMatches}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            {params && (
              <>
                <Tabs
                  value={tabIndex}
                  onChange={(_event, newTabIndex) => {
                    setTabIndex(newTabIndex);
                    setSelectedCarrier([]);
                  }}
                >
                  <Tab label="Old algorithm" />
                  <Tab label="New algorithm" />
                </Tabs>
                <div role="tabpanel">
                  {!loadMatches && isLoadMatchesValidating ? (
                    <CenterContent>
                      <CircularProgress />
                    </CenterContent>
                  ) : loadMatchesError ? (
                    <CenterContent>
                      <Typography color="error">Failed to load data</Typography>
                    </CenterContent>
                  ) : loadMatches?.carriers.length === 0 ? (
                    <CenterContent>
                      <Typography color="textSecondary">
                        No carriers were found
                      </Typography>
                    </CenterContent>
                  ) : (
                    <MatchesResult
                      selectCarrier={selectCarrier}
                      loadMatchesResult={{ loadMatches, refetchLoadMatches }}
                    />
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </GoogleMapsProvider>
  );
}
