import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';

import { convertKmToMiles, digitFormatter } from './FormatUtils';

interface RouteInfoProps {
  direction: google.maps.DirectionsResult | undefined;
}
export function RouteInfo({ direction }: RouteInfoProps) {
  const memoizedDirection = useMemo(() => direction, [direction]);
  const routeInfo = useMemo(() => {
    if (!memoizedDirection) {
      return undefined;
    }

    const [routes] = memoizedDirection.routes;
    const [totalDistance, totalDuration] = routes.legs.reduce(
      ([distance, duration], x) => [
        distance + x.distance.value,
        duration + x.duration.value,
      ],
      [0, 0],
    );
    return { totalDistance, totalDuration };
  }, [memoizedDirection]);

  return (
    <Typography variant="h5">
      {routeInfo
        ? `Distance:
      ${digitFormatter.format(
        convertKmToMiles(routeInfo.totalDistance / 1000),
      )} miles, duration: 
      ${(routeInfo.totalDuration / 3600).toFixed(2)} hours`
        : 'Route'}
    </Typography>
  );
}
