import React from 'react';

import { Marker } from '../google-maps/Marker';
import { CarrierDrivers } from './data/MatchesAPI';

interface DriverMarkerProps {
  position: { lat: number; lng: number };
  options?: google.maps.ReadonlyMarkerOptions;
  carrierName: string;
  driver: CarrierDrivers;
}
export function DriverMarker({
  position,
  options,
  carrierName,
  driver,
}: DriverMarkerProps) {
  return (
    <Marker
      position={position}
      options={options}
      infoWindowContent={`Carrier: <b>${carrierName}</b><br>Driver: <b>${
        driver.name
      }</b><br>Time: <b>${
        driver.location ? new Date(driver.location.time).toLocaleString() : ''
      }<b/>`}
    />
  );
}
