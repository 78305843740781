import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CallMade,
  CallReceived,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { Button } from '@superdispatch/ui';
import { SubmissionErrors } from 'final-form';
import { isEqual } from 'lodash-es';
import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';

import { convertKmToMiles, convertMilesToKm } from './FormatUtils';
import { GeoField, GeoPoint } from './GeoField';
import { MatchesParams, useMatchesContext } from './MatchesContext';
import { MatchesShipperSelect } from './MatchesShipperSelect';

function validate(values: MatchesParams): SubmissionErrors {
  function validatePoint(point?: Partial<GeoPoint>): string | undefined {
    return !point
      ? 'Select Address'
      : point.lat == null || point.lng == null
      ? 'Invalid Address'
      : undefined;
  }

  const ordersMinCount =
    values.ordersMinCount &&
    (!Number(values.ordersMinCount) || Number(values.ordersMinCount) < 1)
      ? 'Invalid value'
      : undefined;

  const ordersMaxCount =
    values.ordersMaxCount &&
    (!Number(values.ordersMaxCount) ||
      Number(values.ordersMaxCount) < 1 ||
      Number(values.ordersMaxCount) < Number(values.ordersMinCount))
      ? 'Invalid value'
      : undefined;

  return {
    pickupPoint: validatePoint(values.pickupPoint),
    deliveryPoint: validatePoint(values.deliveryPoint),
    ordersMaxCount,
    ordersMinCount,
  };
}
interface MatchesSearchParamsProps {
  clearCheckedCarriers: () => void;
}
export function MatchesSearchParams({
  clearCheckedCarriers,
}: MatchesSearchParamsProps) {
  const { params, setParams } = useMatchesContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [formValues, setFormValues] = useState(params);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4">
            Search Params{' '}
            <Box component="small" fontWeight="400">
              (Days half-life period: <strong>{formValues.daysHalfLife}</strong>
              , Distance half-life period:{' '}
              <strong>{formValues.distanceHalfLife}</strong>, Search radius in
              miles:{' '}
              <strong>
                {Math.round(convertKmToMiles(formValues.searchRadius))}
              </strong>
              , Search history in days:{' '}
              <strong>{formValues.searchHistory}</strong>)
            </Box>
          </Typography>
        }
        action={
          <IconButton
            size="small"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <CardContent>
        <Form
          validate={validate}
          onSubmit={setParams}
          initialValues={params}
          initialValuesEqual={isEqual}
          render={({ handleSubmit }) => (
            <Grid
              container={true}
              spacing={2}
              component="form"
              onSubmit={handleSubmit}
            >
              <FormSpy
                onChange={(form) => setFormValues(form.values as MatchesParams)}
              />

              <Grid item={true} xs={12}>
                <Collapse in={isExpanded} timeout="auto">
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={6}>
                      <Field
                        name="daysHalfLife"
                        render={({ input }) => (
                          <>
                            <Typography>
                              Days half-life period ({input.value})
                            </Typography>
                            <Slider
                              {...input}
                              min={10}
                              max={90}
                              step={5}
                              marks={true}
                              valueLabelDisplay="auto"
                              onChange={(_, value) => input.onChange(value)}
                            />
                            <Typography color="textSecondary" variant="caption">
                              Number of days on which load contribution to rank
                              is halved
                            </Typography>
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                      <Field
                        name="distanceHalfLife"
                        render={({ input }) => (
                          <>
                            <Typography>
                              Distance half-life period ({input.value})
                            </Typography>
                            <Slider
                              {...input}
                              min={10}
                              max={100}
                              step={5}
                              marks={true}
                              valueLabelDisplay="auto"
                              onChange={(_, value) => input.onChange(value)}
                            />
                            <Typography color="textSecondary" variant="caption">
                              Distance in kilometers on which load contribution
                              to rank is halved
                            </Typography>
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                      <Field
                        name="searchRadius"
                        format={(n) => Math.round(convertKmToMiles(n))}
                        parse={convertMilesToKm}
                        render={({ input }) => (
                          <>
                            <Typography>
                              Search radius in miles ({input.value})
                            </Typography>
                            <Slider
                              {...input}
                              min={5}
                              max={150}
                              step={5}
                              marks={true}
                              valueLabelDisplay="auto"
                              onChange={(_, value) => input.onChange(value)}
                            />
                          </>
                        )}
                      />
                    </Grid>

                    <Grid item={true} xs={12} md={6}>
                      <Field
                        name="searchHistory"
                        render={({ input }) => (
                          <>
                            <Typography>
                              Search history in days ({input.value})
                            </Typography>
                            <Slider
                              {...input}
                              min={0}
                              max={90}
                              step={5}
                              marks={true}
                              valueLabelDisplay="auto"
                              onChange={(_, value) => input.onChange(value)}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box marginBottom={2} />
                </Collapse>
              </Grid>

              <Grid item={true} xs={12} sm={6} md={3}>
                <Field
                  name="rankingFunction"
                  render={({ input }) => (
                    <TextField
                      {...input}
                      label="Ranking function"
                      select={true}
                      fullWidth={true}
                    >
                      <MenuItem value="harmonic_mean">Harmonic mean</MenuItem>
                      <MenuItem value="product">Product</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item={true} xs={12} sm={6} md={3}>
                <Field
                  name="shipper"
                  render={({ input }) => (
                    <MatchesShipperSelect {...input} label="Shipper" />
                  )}
                />
              </Grid>

              <Grid item={true} xs={12} sm={6} md={3}>
                <Field
                  name="ordersMinCount"
                  type="number"
                  render={({ input, meta }) => {
                    const error = meta.touched && (meta.error as string);

                    return (
                      <TextField
                        {...input}
                        label="New carrier min orders"
                        error={!!error}
                        helperText={error}
                        fullWidth={true}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item={true} xs={12} sm={6} md={3}>
                <Field
                  name="ordersMaxCount"
                  type="number"
                  render={({ input, meta }) => {
                    const error = meta.touched && (meta.error as string);

                    return (
                      <TextField
                        {...input}
                        label="New carrier max orders"
                        error={!!error}
                        helperText={error}
                        fullWidth={true}
                      />
                    );
                  }}
                />
                <Box marginBottom={4} />
              </Grid>

              <Grid item={true} sm={true} xs={12}>
                <Field
                  name="pickupPoint"
                  render={({ input, meta }) => {
                    const error = meta.touched && (meta.error as string);

                    return (
                      <GeoField
                        {...input}
                        helperText={error}
                        error={Boolean(error)}
                        fullWidth={true}
                        placeholder="Pickup Address"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CallMade />
                            </InputAdornment>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item={true} sm={true} xs={12}>
                <Field
                  name="deliveryPoint"
                  render={({ input, meta }) => {
                    const error = meta.touched && (meta.error as string);

                    return (
                      <GeoField
                        {...input}
                        helperText={error}
                        error={Boolean(error)}
                        fullWidth={true}
                        placeholder="Delivery Address"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CallReceived />
                            </InputAdornment>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item={true} sm="auto" xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={clearCheckedCarriers}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </CardContent>
    </Card>
  );
}
