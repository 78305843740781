import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { MatchesContextProvider } from './matches/MatchesContext';
import { MatchesPage } from './matches/MatchesPage';

export function App() {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/" exact={true}>
          <MatchesContextProvider>
            <MatchesPage />
          </MatchesContextProvider>
        </Route>
      </Switch>
    </Suspense>
  );
}
