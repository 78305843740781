import { useDeepEqualMemo } from '@superdispatch/hooks';
import {
  HTTPResource,
  HTTPResourceInput,
  HTTPResourceOptions,
  useHTTPResource,
} from '@superdispatch/http-resource';
import { URITemplateParams } from '@superdispatch/uri';

import { useAPI } from './API';

export type APIResourceBaseOptions<TData> = Omit<
  HTTPResourceOptions<TData>,
  'compare'
>;

export function useAPIResource<
  TData,
  TParams extends URITemplateParams = URITemplateParams
>(
  input: null | HTTPResourceInput<TParams>,
  resourceOptions?: APIResourceBaseOptions<TData>,
): HTTPResource<TData> {
  const resourceInput = useDeepEqualMemo<null | HTTPResourceInput<TParams>>(() => {
    if (!Array.isArray(input)) {
      return input;
    }
    let [template, options] = input;
    if (typeof options == 'object' && typeof options.json == 'object') {
      options = {
        ...options,
        body: JSON.stringify(options.json),
        json: undefined,
        headers: { ...options.headers, 'content-type': 'application/json' },
      };
    }
    return [template, options];
  }, [input]);
  const { requestJSON } = useAPI();

  return useHTTPResource(resourceInput, requestJSON, {
    revalidateOnFocus: false,
    errorRetryCount: 1,
    ...resourceOptions,
  });
}
