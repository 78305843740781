import { useDeepEqualMemo } from '@superdispatch/hooks';
import { useEffect, useState } from 'react';

import { useMap } from './MapRenderer';
import { useGoogleMaps } from './MapsContext';
import { useGoogleMapsAPI } from './MapUtils';

interface DirectionsServiceResult {
  data?: google.maps.DirectionsResult;
  isLoading?: boolean;
  error?: unknown;
}
export function useDirectionsService(
  request?: google.maps.DirectionsRequest,
): DirectionsServiceResult {
  const [state, setState] = useState<DirectionsServiceResult>({});
  const { fetchRoute } = useGoogleMapsAPI();

  const memoizedRequest = useDeepEqualMemo(() => request, [request]);

  useEffect(() => {
    if (!memoizedRequest) {
      return;
    }
    setState({ isLoading: true });
    let current = true;

    fetchRoute(memoizedRequest)
      .then((result) => current && setState({ data: result }))
      .catch((error) => {
        setState({ error: new Error(error) });
      });
    return () => {
      current = false;
      setState({});
    };
  }, [memoizedRequest, setState, fetchRoute]);

  return state;
}

export function DirectionsRenderer({
  directions,
  options,
}: {
  directions?: google.maps.DirectionsResult;
  options?: google.maps.DirectionsRendererOptions;
}) {
  const { maps } = useGoogleMaps();
  const { map } = useMap();
  useEffect(() => {
    if (!maps || !directions) {
      return undefined;
    }
    const renderer = new maps.DirectionsRenderer(options);
    renderer.setMap(map);

    renderer.setDirections(directions);
    return () => renderer.setMap(null);
  }, [maps, map, directions, options]);
  return null;
}
