import {
  Box,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Block, PhoneDisabled, RemoveCircleOutline } from '@material-ui/icons';
import { Button } from '@superdispatch/ui';
import React from 'react';

import { CapableCarrier } from './data/MatchesAPI';
import { CenterContent } from './EstimatedPricesTable';

interface CarrierCapacityTableProps {
  data?: CapableCarrier[];
  isLoading?: boolean;
  error?: Error;
  refetch?: () => void;
}

export function CarrierCapacityTable({
  data,
  isLoading,
  error,
  refetch,
}: CarrierCapacityTableProps) {
  if (isLoading) {
    return (
      <CenterContent>
        <CircularProgress />
      </CenterContent>
    );
  }

  if (error) {
    return (
      <CenterContent>
        <Typography color="error" gutterBottom={true}>
          {error.message}
        </Typography>
        <Button variant="outlined" color="primary" onClick={refetch}>
          Reload
        </Button>
      </CenterContent>
    );
  }

  return (
    <Table size="small" stickyHeader={true}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="center">Capacity</TableCell>
          <TableCell>Phone</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((carrier, i) => (
          <TableRow key={i} hover={true}>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(event: React.MouseEvent) => event.stopPropagation()}
                  href={`https://carrier.superdispatch.com/console/carriers/carrier/${carrier.carrier_id}`}
                >
                  {carrier.name}&nbsp;&nbsp;
                </Link>
                {carrier.is_blacklisted && <Block fontSize="small" />}
                {carrier.exclusion_reason === 'do_not_call' && (
                  <PhoneDisabled color="action" fontSize="small" />
                )}
                {carrier.exclusion_reason === 'remove_totally' && (
                  <RemoveCircleOutline fontSize="small" color="error" />
                )}
              </Box>
            </TableCell>
            <TableCell align="center">
              <strong>{carrier.vehicle_count}</strong>
            </TableCell>
            <TableCell>{carrier.phone_numbers}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
