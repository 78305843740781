import { useCallback, useMemo } from 'react';

import { useGoogleMaps } from './MapsContext';

let nextFetchTime = 0;

export function useGoogleMapsAPI() {
  const { maps } = useGoogleMaps();
  const service = useMemo(() => maps && new maps.DirectionsService(), [maps]);
  const fetchRoute = useCallback(
    (
      request: google.maps.DirectionsRequest,
    ): Promise<google.maps.DirectionsResult> =>
      new Promise((resolve, reject) => {
        const now = Date.now();
        const delta = 300 - now - nextFetchTime;
        const timeout = delta < 0 ? 0 : delta;

        nextFetchTime = now + timeout;
        //executes the route request function not earlier than 300 milliseconds to avoid api abuse
        setTimeout(() => {
          service?.route(request, (result, status) => {
            if (status === maps?.DirectionsStatus.OK) {
              resolve(result);
            } else {
              reject(new Error(status));
            }
          });
        }, timeout);
      }),
    [maps, service],
  );
  return { fetchRoute };
}
