import { TextField } from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField';
import { round } from 'lodash-es';
import React, { useEffect, useRef } from 'react';
import { useEventCallback } from 'utility-hooks';

import { useGoogleMaps } from '../google-maps/MapsContext';

export interface GeoPoint {
  lat: number;
  lng: number;
  address: string;
}

export interface GeoFieldProps
  extends Omit<StandardTextFieldProps, 'value' | 'onChange'> {
  value?: GeoPoint;
  onChange: (value?: Partial<GeoPoint>) => void;
}

export function GeoField({ value, onChange, ...props }: GeoFieldProps) {
  const ref = useRef<HTMLInputElement>(null);
  const { maps } = useGoogleMaps();
  const handleChange = useEventCallback(onChange);

  useEffect(() => {
    if (!maps || !ref.current) {
      return;
    }

    const autocomplete = new maps.places.Autocomplete(ref.current, {
      fields: ['geometry', 'address_components'],
      componentRestrictions: { country: ['USA'] },
    });

    autocomplete.addListener('place_changed', () => {
      const {
        geometry,
        address_components: addressComponents,
      } = autocomplete.getPlace();

      let zip = '';
      let city = '';
      let stateCode = '';

      if (addressComponents) {
        addressComponents.forEach(
          ({ types, long_name: longName, short_name: shortName }) => {
            if (types.includes('postal_code')) {
              zip = longName;
            } else if (types.includes('locality')) {
              city = longName;
            } else if (types.includes('administrative_area_level_1')) {
              stateCode = shortName;
            }
          },
        );
      }

      const nextAddress = [city, [zip, stateCode].filter(Boolean).join(' ')]
        .filter(Boolean)
        .join(', ');

      if (geometry && nextAddress) {
        handleChange({
          address: nextAddress,
          lat: round(geometry.location.lat(), 6),
          lng: round(geometry.location.lng(), 6),
        });
      }
    });

    return () => maps.event.clearInstanceListeners(autocomplete);
  }, [maps, handleChange]);

  return (
    <TextField
      {...props}
      inputRef={ref}
      value={value?.address || ''}
      onChange={(event) => onChange({ address: event.target.value })}
    />
  );
}
