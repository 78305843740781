import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbarStack } from '@superdispatch/ui';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { useSearchShipper } from './data/MatchesAPI';
import { Shipper } from './MatchesContext';

interface Props {
  label?: string;
  value?: Shipper;
  onChange: (value: Shipper | null) => void;
}
export function MatchesShipperSelect({ onChange, value, label }: Props) {
  const [searchValue, setSearchValue] = useState(value?.name || '');
  const { addSnackbar } = useSnackbarStack();
  const { data: shippers, error: shippersError } = useSearchShipper(
    searchValue,
  );
  const options = useMemo<Shipper[]>(
    () =>
      !shippers
        ? []
        : Object.keys(shippers).map((key) => ({
            guid: key,
            name: shippers[key],
          })),
    [shippers],
  );

  useEffect(() => {
    if (shippersError) {
      addSnackbar('Error occured while loading shippers', { variant: 'error' });
    }
  }, [shippersError, addSnackbar]);

  return (
    <Autocomplete
      options={options}
      onSelect={(event: ChangeEvent<HTMLInputElement>) =>
        setSearchValue(event.target.value)
      }
      onChange={(_, nextValue) => onChange(nextValue)}
      getOptionLabel={(option: Shipper) => option.name || ''}
      value={value}
      loading={!shippers && !shippersError}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={true}
          placeholder="Type shipper name"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!shippers && !shippersError && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
