import { useDeepEqualMemo } from '@superdispatch/hooks';

import { useAPIList } from '../api/APIList';
import { useAPIResource } from '../api/APIResource';
import { RankingFunctionName, useMatchesContext } from '../MatchesContext';

interface LoadMatchingParams {
  pickup_latitude: number;
  pickup_longitude: number;
  delivery_latitude: number;
  delivery_longitude: number;
  days_half_life: number;
  dist_half_life: number;
  search_history: number;
  search_radius: number;
  ranking_function: RankingFunctionName;
  shipper_guid?: string;
  orders_min_count?: number;
  orders_max_count?: number;
  carriers?: number[];
}

export function useMatchingParams(
  extraParams?: Partial<LoadMatchingParams>,
): LoadMatchingParams | undefined {
  const { params } = useMatchesContext();
  return useDeepEqualMemo(
    () =>
      !params.pickupPoint || !params.deliveryPoint
        ? undefined
        : {
            ...extraParams,
            pickup_latitude: params.pickupPoint.lat,
            pickup_longitude: params.pickupPoint.lng,
            delivery_latitude: params.deliveryPoint.lat,
            delivery_longitude: params.deliveryPoint.lng,
            days_half_life: params.daysHalfLife,
            dist_half_life: params.distanceHalfLife,
            search_history: params.searchHistory,
            search_radius: params.searchRadius,
            ranking_function: params.rankingFunction || 'harmonic_mean',
            shipper_guid: params.shipper ? params.shipper.guid : undefined,
            orders_min_count: Number(params.ordersMinCount) || undefined,
            orders_max_count: Number(params.ordersMaxCount) || undefined,
          },
    [params, extraParams],
  );
}
interface DriverLoad {
  number: string;
  price: string;
  created_by: {
    role: string;
    name: string;
    phone: string | null;
  };
  pickup_date: string;
  pickup_name: string;
  pickup_city: string;
  pickup_state: string;
  pickup_zip: number;
  pickup_latitude: number;
  pickup_longitude: number;
  delivery_date: string;
  delivery_name: string;
  delivery_city: string;
  delivery_state: string;
  delivery_zip: number;
  delivery_latitude: number;
  delivery_longitude: number;
}
export interface DriverPoint {
  type: 'pickup' | 'delivery';
  created_by: {
    role: string;
    name: string;
    phone: string | null;
  };
  number: string;
  price: number;
  date: string;
  name: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
}
export interface CarrierDrivers {
  guid: string;
  name: string;
  location?: {
    latitude: number;
    longitude: number;
    time: string;
  };
  loads: DriverLoad[];
  points: DriverPoint[];
}

export interface MatchedCarrier {
  carrier_id: number;
  email: string;
  exclusion_reason: 'do_not_call' | 'remove_totally';
  guid: string;
  is_blacklisted: boolean;
  is_super: boolean;
  name: string;
  phone_numbers: string;
  total_rank: number;
  us_dot: string;
  note: string;
  drivers?: CarrierDrivers[];
}
export interface LoadMatchesResponse {
  carriers: MatchedCarrier[];
  location: {
    delivery_latitude: number;
    delivery_longitude: number;
    id: number;
    pickup_latitude: number;
    pickup_longitude: number;
  };
}
export function useLoadMatches() {
  const json = useMatchingParams();

  return useAPIResource<LoadMatchesResponse>(
    json ? ['POST /internal/api/web/load_matching', { json }] : null,
  );
}

export function useNewLoadMatches() {
  const json = useMatchingParams();

  return useAPIResource<LoadMatchesResponse>(
    json
      ? ['POST /internal/api/web/load_matching/current_trips', { json }]
      : null,
  );
}

interface PricePrediction {
  estimated_price: number;
  estimated_price_per_km: number;
  is_inoperable: boolean;
  vehicle_type_name: string;
}

export function usePricePredictions() {
  const json = useMatchingParams();

  return useAPIList<PricePrediction>(
    json ? ['POST /internal/api/price_prediction', { json }] : null,
  );
}

export interface RouteFrequency {
  carrier_id: number;
  route_frequency: number;
}

export interface EnclosedInoperable {
  carrier_id: number;
  enclosed: number;
  inoperable: number;
}

export interface LastPrice {
  delivered_date: string;
  delivery_city: string;
  delivery_distance: number;
  delivery_state: string;
  load_count: number;
  name: string;
  phone_numbers: string;
  pickup_city: string;
  pickup_distance: number;
  pickup_state: string;
  price: number;
  vehicle_types: string;
  id: number;
}

export interface NearbyCarriers {
  route_frequency: RouteFrequency[];
  enclosed_and_inoperable: EnclosedInoperable[];
  last_prices: LastPrice[];
}

export function useNearbyCarriers(carriers: number[]) {
  const json = useMatchingParams({ carriers });

  return useAPIResource<NearbyCarriers>(
    json && carriers.length > 0
      ? ['POST /internal/api/nearby_carriers', { json }]
      : null,
  );
}

export function useSearchShipper(query: string) {
  return useAPIResource<Record<string, string>>([
    'POST /internal/shippers/search',
    { json: { name: query } },
  ]);
}

export interface CapableCarrier {
  carrier_id: number;
  email: string;
  exclusion_reason: 'do_not_call' | 'remove_totally';
  guid: string;
  is_blacklisted: boolean;
  name: string;
  order_id: number;
  phone_numbers: string;
  vehicle_count: number;
}

export function useBigTrailerCarriers(carriers: number[]) {
  const params = useMatchingParams();

  return useAPIList<CapableCarrier>(
    carriers.length > 0
      ? [
          'POST /internal/api/web/big_trailer',
          { json: { carriers, shipper_guid: params?.shipper_guid } },
        ]
      : null,
  );
}

export interface NewCarrier {
  carrier_guid: string;
  carrier_id: number;
  email: string;
  exclusion_reason: 'do_not_call' | 'remove_totally';
  is_blacklisted: boolean;
  name: string;
  order_id: number;
  phone_numbers: string;
  us_dot: string;
}

export function useNewCarriers() {
  const json = useMatchingParams();
  return useAPIList<NewCarrier>(
    json ? ['POST /internal/api/web/new_carriers', { json }] : null,
  );
}
